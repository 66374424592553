<template>
  <section class="fishes">
    <div class="fishes-wrapper">
      <h2 class="fishes__h2">Виды рыб</h2>
      <div class="fishes-grid">
        <swiper
            :slides-per-view="'auto'"
            :space-between="16"
            :free-mode="{enabled: true, sticky: false}"
            :grid="{rows: 1}"
            :navigation="{
              'prevEl': '.fishes-grid__navigation.prev',
              'nextEl': '.fishes-grid__navigation.next'
            }"
            :breakpoints="{
              599  : {
                'spaceBetween': 20,
                'grid': {
                  'rows': 2,
                },
              },
              1023  : {
                'slidesPerGroup': 2,
                'freeMode': false,
                'slidesPerView': 2,
                'grid': {
                  'rows': 2,
                },
              },
              1439  : {
                'slidesPerGroup': 3,
                'freeMode': false,
                'slidesPerView': 3,
                'grid': {
                  'rows': 2,
                },
              }
            }"
        >
          <swiper-slide v-for="fish in fishes" :key="fish.id" >
            <fish-card :fish="fish"/>
          </swiper-slide>
        </swiper>
        <div class="fishes-grid__navigation">
          <router-link :to="{name: 'fishes'}" class="btn btn-secondary btn-m">Смотреть все</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import FishCard from "components/FishCard";


export default defineComponent({
  name: "FishesGrid",
  props: ['fishes'],
  components: {
    FishCard,
  },
  data() {
    return {


    };
  },
  methods: {

  }
});
</script>

<style lang="scss">
  @import "src/css/variables";
  @import "src/css/mixins";

  .fishes {
    padding: 40px 0;
    overflow: hidden;

    @media #{$tablet-widths} {
      padding: 60px 0;
    }

    @media #{$desktop-widths} {
      padding: 100px 0;
    }

    .swiper {
      overflow: visible;
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      @media #{$tablet-widths} {
        height: 364px;
      }

      @media #{$desktop-widths} {
        overflow: hidden;
        padding-bottom: 20px;
        height: 380px;
        width: calc(100% + 15px);
        padding-right: 15px;
      }
    }

    .swiper-slide {
      width: 170px;

      @media #{$tablet-widths} {
        height: calc((100% - 20px) / 2);
        width: 440px;
      }

      @media #{$desktop-l-widths} {
        width: calc((100% - 40px) / 3);
      }
    }
  }

  .fishes-wrapper {
    @include wrapper();
  }

  .fishes__h2 {
    margin-bottom: 24px;

    @media #{$tablet-widths} {
      margin-bottom: 32px;
    }

    @media #{$desktop-widths} {
      margin-bottom: 47px;
    }
  }

  .fishes-grid {
    position: relative;
  }

  .fishes-grid__navigation {
    display: flex;
    margin-top: 24px;
    > a {
      width: 100%;
    }

    @media #{$desktop-widths} {
      width: unset;
      display: flex;
      position: absolute;
      right: 0;
      bottom: calc(100% + 48px);
      > a {
        width: unset;
      }
    }
  }
</style>
