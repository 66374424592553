<template>
  <section class="workflow">
    <div class="wrapper">
      <h2 class="workflow__h2">Как это работает</h2>

      <q-list class="workflow-accordion">
        <q-expansion-item
            class="workflow-accordion__item"
            group="workflow"
            default-opened
            header-class="workflow-accordion__item-header"
        >
          <template v-slot:header>
            <q-item-section class="workflow-accordion__item-number">
              <span>1</span>
            </q-item-section>

            <q-item-section class="workflow-accordion__item-title">
              Выберите гида по удобным для Вас параметрам
            </q-item-section>
          </template>
          <q-card class="workflow-accordion__item-content">
            <q-card-section class="workflow-accordion__item-container">
              <img src="../assets/images/workflow/01.webp" alt="" loading="lazy">
            </q-card-section>
          </q-card>
        </q-expansion-item>

        <q-expansion-item
            group="workflow"
            class="workflow-accordion__item"
            header-class="workflow-accordion__item-header"
            active-class="active"
        >
          <template v-slot:header>
            <q-item-section class="workflow-accordion__item-number">
              <span>2</span>
            </q-item-section>

            <q-item-section class="workflow-accordion__item-title">
              Забронируйте дату, договоритесь об условиях
            </q-item-section>
          </template>
          <q-card class="workflow-accordion__item-content">
            <q-card-section class="workflow-accordion__item-container">
              <img src="../assets/images/workflow/02.webp" alt="" loading="lazy">
            </q-card-section>
          </q-card>
        </q-expansion-item>

        <q-expansion-item
            group="workflow"
            class="workflow-accordion__item"
            header-class="workflow-accordion__item-header"
            active-class="active"
        >
          <template v-slot:header>
            <q-item-section class="workflow-accordion__item-number">
              <span>3</span>
            </q-item-section>

            <q-item-section class="workflow-accordion__item-title">
              Наслаждайтесь отдыхом.
              Поделитесь своими впечатлениями
            </q-item-section>
          </template>
          <q-card class="workflow-accordion__item-content">
            <q-card-section class="workflow-accordion__item-container">
              <img src="../assets/images/workflow/03.webp" alt="" loading="lazy">
            </q-card-section>
          </q-card>
        </q-expansion-item>

      </q-list>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Workflow"
});
</script>

<style lang="scss">
  @import "src/css/variables";
  @import "src/css/mixins";

  .workflow {
    background: $color-surface-grey;
    padding-top: 40px;
    padding-bottom: 40px;

    @media #{$tablet-widths} {
      padding: 48px 0;
    }

    @media #{$desktop-widths} {
      padding: 80px 0;
    }
  }

  .workflow__h2 {
    margin-bottom: 24px;

    @media #{$tablet-widths} {
      margin-bottom: 32px;
    }
  }

  .workflow-accordion {
    position: relative;

    @media #{$desktop-widths} {
      width: 512px;

      .q-expansion-item__container {
        position: static;
      }

      .q-expansion-item__content {
        position: absolute;
        left: 672px;
        top: 0;
        width: 276px;
      }
    }

    @media #{$desktop-l-widths} {
      .q-expansion-item__content {
        width: 652px;
        height: calc(100% + 90px);
        top: -90px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .workflow-accordion__item {
    & + & {
      margin-top: 16px;

      @media #{$desktop-l-widths} {
        margin-top: 12px;
      }
    }
  }

  .workflow-accordion__item-header {
    display: flex;
    align-items: flex-start;
    border-radius: $border-radius;
    border: $border;
    padding: 16px;
    background: transparent;
    @include transition(background);
    margin-bottom: 16px;

    @media #{$tablet-widths} {
      align-items: center;
    }

    @media #{$desktop-widths} {
      &:hover {
        background: $color-white;
      }
    }

    @media #{$desktop-l-widths} {
      align-items: flex-start;
      padding: 24px;
      margin-bottom: 0;
    }

    &:hover {
      .q-focus-helper {
        background: transparent!important;
      }
    }

    .q-expansion-item--expanded & {
      background: $color-primary-500;

      .workflow-accordion__item-number {
        background: $color-primary-400;
      }

      .workflow-accordion__item-title {
        color: #fff;
      }
    }

    .q-icon {
      display: none;
    }
  }

  .workflow-accordion__item-number {
    @include size(32px);
    flex: 0 0 32px;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-white;
    background: $color-primary-500;
    @include transition(background);
    font-weight: 600;
    @include font(16px, 24px);
    border-radius: 50%;

    @media #{$tablet-widths} {
      @include size(48px);
      flex: 0 0 48px;
      margin-right: 16px;
      @include font(20px, 28px);
    }
  }

  .workflow-accordion__item-title {
    color: $text-color-primary;
    @include transition(color);
    @include font(16px, 24px);

    @media #{$tablet-widths} {
      @include font(20px, 28px);
    }
  }

  .workflow-accordion__item-content {
    border-radius: $border-radius!important;
    overflow: hidden;
    background: $color-white;
    display: flex;
    align-items: center;
    justify-content: center;

    @media #{$desktop-widths} {
      height: 100%;
    }
  }

  .workflow-accordion__item-container {
    padding: 0;
  }
</style>
