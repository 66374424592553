<template>
  <div class="top-banner">
    <div class="top-banner__wrapper">
      <div class="top-banner__title">Найди своего гида для рыбалки</div>
      <q-form
          @submit="findGuides"
          class="top-banner__form"
      >
        <q-input
            v-model="text"
            placeholder="Поиск по месту, водоёму или гиду"
            class="top-banner__form-input input"
        >
          <template v-slot:prepend>
            <q-icon name="o_place" />
          </template>
        </q-input>
        <multi-calendar
          ref="datepickerRef"
          class="top-banner__form-input  picker picker_short"
          :is-input="true"
          :menu-class-name="'picker__menu_short'"
          enableTimePicker
          @date-update="(d) => {date = d}"
        >
          <template v-slot:time-picker>
            <q-checkbox
                v-model="strictDates"
                :val="true" label="Строгое совпадение даты" class="checkbox"
            />
          </template>
        </multi-calendar>
        <q-btn
            :ripple=false
            label="Найти"
            type="submit"
            class="top-banner__form-btn"
        />
      </q-form>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MultiCalendar from "components/MultiCalendar";

export default defineComponent({
  name: "TopBanner",
  components: {MultiCalendar},
  data: () => ({
    text: '',
    date: null,
    strictDates: false,
  }),
  methods: {
    findGuides() {
      const query = {}
      if (this.text) query.search = this.text;
      if (this.date) query.dateRange = JSON.stringify(this.date);
      if (this.strictDates) query.strictDates = 'true'

      this.$router.push({name: 'guides-search', query: query});
    },
  }
});
</script>

<style lang="scss">
  @import "src/css/variables";
  @import "src/css/mixins";

  .top-banner {
    background: url("../assets/images/top-banner/top-banner-bg-mobile.webp") center / cover no-repeat;
    min-height: 460px;
    margin-bottom: 40px;

    @media #{$tablet-widths} {
      min-height: 600px;
      margin-bottom: 60px;
    }

    @media #{$desktop-widths} {
      background-image: url("../assets/images/top-banner/top-banner-bg.webp");
      margin-bottom: 100px;
    }

    .picker {
      input {
        padding: 15px 0 15px 40px;
      }
    }
  }

  .top-banner__wrapper {
    @include wrapper();
    padding-top: 112px;
    padding-bottom: 68px;

    @media #{$tablet-widths} {
      padding-top: 154px;
      padding-bottom: 90px;
    }

    @media #{$tablet-widths} {
      padding-top: 209px;
      padding-bottom: 209px;
    }
  }

  .top-banner__title {
    font-family: $font-heading;
    letter-spacing: 1px;
    color: $color-white;
    @include font(40px, 44px);
    margin-bottom: 24px;
    text-transform: uppercase;

    @media #{$tablet-widths} {
      @include font(70px, 78px);
      margin-bottom: 32px;
    }

    @media #{$desktop-widths} {
      margin-bottom: 40px;
    }
  }

  .top-banner__form {
    border-radius: $border-radius;
    overflow: hidden;

    @media #{$desktop-widths} {
      display: flex;
      border-radius: 40px;
    }
  }

  .top-banner__form-input {
    background: $color-white;

    @media #{$desktop-widths} {
      flex: 0 0 25%;

      &:first-child {
        flex: 0 0 55%;
        border-right: $border;
      }
    }

    .q-field__prepend {
      padding-bottom: 18px;
      padding-left: 16px;
    }

    input {
      padding: 16px 0;
    }

    i {
      color: $text-color-secondary;
      font-size: 24px;
    }
  }

  .top-banner__form-btn {
    display: block;
    width: 100%;
    background: $color-primary-500;
    color: $color-white;
    text-transform: none;
    padding: 20px;
    @include font(16px);
    @include transition(background);

    &:hover {
      background: $color-primary-400;
    }

    @media #{$desktop-widths} {
      flex: 0 0 20%;
    }
  }
</style>
