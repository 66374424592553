<template>
  <section class="guides">
    <div class="guides-wrapper">
      <h2 class="guides__h2">Рекомендуемые гиды</h2>
      <div class="guides-grid">
        <div class="guides-grid__navigation next swiper-button">
          <q-icon name="arrow_forward" />
        </div>
        <div class="guides-grid__navigation prev swiper-button">
          <q-icon name="arrow_back" />
        </div>
          <q-no-ssr>
              <swiper
                  :slides-per-view="'auto'"
                  :space-between="16"
                  :free-mode="{enabled: true, sticky: false}"
                  :grid="{rows: 1}"
                  :navigation="{
              'prevEl': '.guides-grid__navigation.prev',
              'nextEl': '.guides-grid__navigation.next'
            }"
                  :breakpoints="{
              1023  : {
                'slidesPerGroup': 3,
                'freeMode': false,
                'slidesPerView': 3,
                'spaceBetween': 20,
                'grid': guides.length > 3 ? { 'rows': 2,} : false,
                'containerModifierClass' : guides.length > 3 ? 'grid-' : '',
              },
              1439  : {
                'slidesPerGroup': 4,
                'freeMode': false,
                'slidesPerView': 4,
                'spaceBetween': 20,
                'grid': guides.length > 4 ? { 'rows': 2,} : false,
                'containerModifierClass' : guides.length > 4 ? 'grid-' : '',
              }
            }"
              >
                  <swiper-slide v-if="$auth.canAny(['guide']) && !$auth.canAny(['top20'])">
                      <premium-card/>
                  </swiper-slide>
                  <swiper-slide v-for="card in guides" :key="card.id" >
                      <guide-card :isChecked="card.premium_abilities.indexOf('recommended') !== -1" :card="card" />
                  </swiper-slide>
              </swiper>
              <template v-slot:placeholder>
                  <div v-for="card in guides" :key="card.id" >
                      <guide-card :isChecked="card.premium_abilities.indexOf('recommended') !== -1" :card="card" />
                  </div>
              </template>
          </q-no-ssr>

      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import GuideCard from "components/GuideCard";
import PremiumCard from "components/PremiumCard";

export default defineComponent({
  name: "GuidesGrid",
  props: ['guides'],
  components: {
    GuideCard,
    PremiumCard,
  },
});
</script>

<style lang="scss">
  @import "src/css/variables";
  @import "src/css/mixins";

  .guides {
    padding-bottom: 40px;
    overflow: hidden;

    @media #{$tablet-widths} {
      padding-bottom: 60px;
    }

    @media #{$desktop-widths} {
      padding-bottom: 100px;
    }

    .swiper {
      overflow: visible;
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      @media #{$desktop-widths} {
        overflow: hidden;
        padding-bottom: 30px;
        width: calc(100% + 40px);
        margin-left: -20px;
        padding-right: 20px;
        padding-left: 20px;
      }

      &.grid-initialized {
        @media #{$desktop-widths} {
          height: 960px;
        }

        @media #{$desktop-l-widths} {
          height: 958px;
        }
      }
    }

    .swiper-slide {
      width: 280px;

      @media #{$desktop-widths} {
        height: calc((100% - 20px) / 2);
      }
    }
  }

  .guides-wrapper {
    @include wrapper();
  }

  .guides-grid {
    position: relative;
  }

  .guides-grid__navigation {
    display: none;

    @media #{$desktop-widths} {
      display: flex;
      position: absolute;
      bottom: calc(100% + 48px);

      &.next {
        right: 0;
      }

      &.prev {
        right: 68px;
      }
    }
  }

  .guides__h2 {
    margin-bottom: 24px;

    @media #{$tablet-widths} {
      margin-bottom: 32px;
    }

    @media #{$tablet-widths} {
      margin-bottom: 47px;
    }
  }
</style>
