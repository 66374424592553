<template>
  <section class="guides">
    <div class="guides-wrapper">
      <h2 class="guides__h2">Новые гиды</h2>
      <div class="guides-grid">
          <q-no-ssr>
              <swiper
                  :slides-per-view="'auto'"
                  :space-between="16"
                  :free-mode="{enabled: true, sticky: false}"
                  :grid="{rows: 1}"
                  :breakpoints="{
              1023  : {
                'slidesPerView': 4,
                'spaceBetween': 20
              }
            }"

              >
                  <swiper-slide v-for="card in guides" :key="card.id" >
                      <guide-card :card="card"/>
                  </swiper-slide>
              </swiper>
              <template v-slot:placeholder>
                  <div v-for="card in guides" :key="card.id" >
                      <guide-card :card="card"/>
                  </div>
              </template>
          </q-no-ssr>

      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import GuideCard from "components/GuideCard";

export default defineComponent({
  name: "NewGuides",
  props: ['guides'],
  components: {
    GuideCard,
  },
  data() {
    return {


    };
  },
  methods: {

  }
});
</script>

<style lang="scss" scoped>
@import "src/css/variables";
@import "src/css/mixins";

.swiper {

  @media #{$desktop-widths} {
    height: auto;
  }

  @media #{$desktop-l-widths} {
    height: auto;
  }
}
</style>
