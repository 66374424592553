<template>
  <section class="places">
    <div class="wrapper">
      <h2 class="places__h2">Города</h2>
      <div class="places-grid">
        <place-card
            v-for="(city, i) in cities"
            :is-city="true"
            :key="city.id"
            :title="city.name"
            :subtitle="''"
            :cover="city.preview.medium"
            :slug="city.slug"
            :class="i === 0 ? 'big' : ''"
        />
      </div>
      <div class="places-bottom">
        <router-link :to="{name: 'cities'}" class="btn btn-secondary btn-m">Смотреть все</router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import PlaceCard from "components/PlaceCard";

export default defineComponent({
  name: "MainCities",
  components: {
    PlaceCard,
  },
  props: ['cities'],
  data() {
    return {


    };
  },
  methods: {

  }
});
</script>

<style lang="scss" >
@import "src/css/variables";
@import "src/css/mixins";

.places {
  padding-bottom: 40px;

  @media #{$tablet-widths} {
    padding-bottom: 60px;
  }

  @media #{$desktop-widths} {
    padding-bottom: 100px;
  }

  .wrapper {
    @media #{$tablet-widths} {
      position: relative;
    }
  }
}

.places__h2 {
  margin-bottom: 24px;

  @media #{$tablet-widths} {
    margin-bottom: 32px;
  }

  @media #{$desktop-widths} {
    margin-bottom: 48px;
  }
}

.places-grid {
  @media #{$desktop-widths} {
    display: grid;
    grid-template-rows: 340px 340px;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .place-card {
    &:nth-child(1) {
      grid-column: 1;
      grid-row: 1/3;
    }
    &:nth-child(2) {
      grid-column: 2;
      grid-row: 1;
    }
    &:nth-child(3) {
      grid-column: 2;
      grid-row: 2;
    }
  }
}

.places-bottom {
  margin-top: 24px;

  @media #{$tablet-widths} {
    margin-top: 0;
  }

  .btn {
    width: 100%;

    @media #{$tablet-widths} {
      width: 190px;
      position: absolute;
      right: 26px;
      top: 0;
    }

    @media #{$desktop-widths} {
      right: 38px;
    }

    @media #{$desktop-l-widths} {
      right: 58px;
    }
  }
}
</style>
