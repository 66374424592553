<template>
  <top-banner/>
  <guides-grid v-if="guides.length > 0" :guides="guides"/>
  <main-reports v-if="reports.length > 0" :reports="reports"/>
  <workflow/>
  <main-places v-if="waters.length > 0"  :waters="waters"/>
  <main-fishes v-if="fishes.length > 0" :fishes="fishes"/>
  <main-cities v-if="cities.length > 0" :cities="cities"/>
  <new-guides v-if="newGuides.length > 0" :guides="newGuides"/>
  <ui-popup
    v-model="popup"
    text="Страница, которую вы искали, не найдена. Попробуйте воспользоваться формой поиска"
    icon="close"
    color="error"

    @hide="$router.push('/')"
  />
</template>

<script>
import {defineComponent} from "vue";
import TopBanner from "components/TopBanner";
import GuidesGrid from "components/GuidesGrid";
import MainReports from "components/MainReports";
import NewGuides from "components/NewGuides";
import Workflow from "components/Workflow";
import MainPlaces from "components/MainPlaces";
import MainFishes from "components/MainFishes";
import MainCities from "components/MainCities";
import {axios} from "boot/auth";
import {createMetaMixin} from "quasar";
import UiPopup from "components/ui/UiPopup.vue";

export default defineComponent({
  name: "PageMainUnauthorized",
  components: {
    UiPopup,
    MainCities,
    TopBanner,
    GuidesGrid,
    MainReports,
    NewGuides,
    Workflow,
    MainPlaces,
    MainFishes,
  },
    async serverPrefetch() {
      await Promise.all([
        axios.get('general/top-guides').then(res => {
          this.guides = res.data;
        }),
        axios.get('general/new-guides').then(res => {
          this.newGuides = res.data;
        }).catch(e => {console.log('pre errror' + e)}),
        axios.get('general/reports/last').then(res => {
          this.reports = res.data;
        }).catch(e => {console.log('pre errror' + e)}),
        axios.get('general/waters/near').then(res => {
          this.waters = res.data;
        }).catch(e => {console.log('pre errror' + e)}),
        axios.get('general/waters/fish').then(res => {
          this.fishes = res.data;
        }).catch(e => {console.log('pre errror' + e)}),
        axios.get('general/cities/all').then(res => {
          this.cities = res.data.data;
        }).catch(e => {console.log('pre errror' + e)})
      ])
        .then((graphs) => {
          // loaderHide()
        }).catch((e) => {
          // loaderHide()
        })
        // await axios.get('general/top-guides').then(res => {
        //     this.guides = res.data;
        // }).catch(e => {console.log('pre errror' + e)})
        // await axios.get('general/new-guides').then(res => {
        //     this.newGuides = res.data;
        // }).catch(e => {console.log('pre errror' + e)})
        // await axios.get('general/reports/last').then(res => {
        //     this.reports = res.data;
        // }).catch(e => {console.log('pre errror' + e)})
        // await axios.get('general/waters/near').then(res => {
        //     this.waters = res.data;
        // }).catch(e => {console.log('pre errror' + e)})
        // await axios.get('general/waters/fish').then(res => {
        //     this.fishes = res.data;
        // }).catch(e => {console.log('pre errror' + e)})
        // await axios.get('general/cities/all').then(res => {
        //     this.cities = res.data.data;
        // }).catch(e => {console.log('pre errror' + e)})
    },
    data () {
    return {
      popup: false,
      guides: [],
      newGuides: [],
      reports: [],
      waters: [],
      fishes: [],
      cities: [],
    };
  },
  mixins: [
    createMetaMixin({
      title: 'Рыбалка с гидом'
    })
  ],
  mounted() {
    if(this.$route.query.token) {
      let token = this.$route.query.token;
      this.$router.replace({'query': null});
      setTimeout(() => {
        this.$auth.loginViaToken(token)
      }, 1000)
    }
    if(this.$route.query.not_found === '1') {
      this.popup = true;
    }
    this.$api.get('general/top-guides').then(res => {
      this.guides = res.data;
    })
    this.$api.get('general/new-guides').then(res => {
      this.newGuides = res.data;
    })
    this.$api.get('general/reports/last').then(res => {
      this.reports = res.data;
    })
    this.$api.get('general/waters/near').then(res => {
      this.waters = res.data;
    })
    this.$api.get('general/waters/fish').then(res => {
      this.fishes = res.data;
    })
    this.$api.get('general/cities/all').then(res => {
      this.cities = res.data.data;
    })
  }
});
</script>
